import { EBetGroupName } from './Enums';
export const COOKIE_KEYS = { LANGUAGE_CODE: 'LANGUAGE_CODE' };

export const TMM_URL = process.env.REACT_APP_TMM_URL;
export const BACKOFFICE_URL = process.env.REACT_APP_BACKOFFICE_URL;
export const AWS_BUCKET_URL = process.env.REACT_APP_AWS_BUCKET_URL;

export const HomeIcon = `${AWS_BUCKET_URL}/Icons/home.svg`;

export const betType = [
  { code: 1, description: 'Winner' },
  { code: 8, description: 'First Goal' },
  { code: 9, description: 'Last Goal' },
  { code: 10, description: 'Double Chance' },
  { code: 14, description: 'Handicap' },
  { code: 18, description: 'Over/Under' },
  { code: 4, description: 'Next Goal' },
  { code: 5, description: '1st Halftime' },
  { code: 6, description: '1st Halftime Handicap' },
  { code: 7, description: '1st Halftime Over/Under' },
  { code: 8, description: '1st Halftime Next Goal' },
  { code: 10, description: '1st Halftime Double Chance' },
  { code: 11, description: 'Both To Score' },
  { code: 12, description: '1st Halftime Both To Score' },
  { code: 13, description: 'Draw no bet' },
  { code: 14, description: '1st Halftime Draw no bet' }
];

export const BetGroups = {
  [EBetGroupName.TOP_BETS]: {
    tabOrder: 1,
    markets: [
      1, 2, 3, 4, 7, 8, 10, 11, 14, 16, 18, 9, 52, 25, 29, 41, 47, 112, 113, 114, 115, 116, 123,
      186, 202, 219, 234, 251, 272, 292, 296, 897, 340, 499
    ]
  },
  [EBetGroupName.Over_Under]: {
    tabOrder: 2,
    markets: [
      18, 19, 20, 58, 59, 68, 69, 70, 90, 91, 92, 189, 225, 235, 236, 258, 260, 261, 238, 494
    ]
  },
  [EBetGroupName.FIRST_HALF_TIME]: {
    tabOrder: 3,
    markets: [60, 61, 62, 63, 64, 65, 68, 69, 70, 71, 72, 73, 74, 75, 80, 174, 176, 177, 552]
  },
  [EBetGroupName.SECOND_HALF_TIME]: {
    tabOrder: 4,
    markets: [83, 84, 85, 86, 90, 91, 92, 94, 95]
  },
  [EBetGroupName.HANDICAP]: {
    tabOrder: 5,
    markets: [14, 16, 65, 66, 187, 188, 203, 223, 237, 256, 493]
  },

  [EBetGroupName.CORNER]: {
    tabOrder: 6,
    markets: [162, 166]
  },
  [EBetGroupName.GOALS]: {
    tabOrder: 7,
    markets: [8, 9, 21, 23, 24, 25, 26, 29, 30, 33, 34, 551]
  },
  [EBetGroupName.PLAYER]: {
    tabOrder: 8,
    markets: [38, 39, 40, 775, 888, 891, 892, 898, 900, 901]
  },
  [EBetGroupName.DOUBLE_BETS]: {
    tabOrder: 9,
    markets: [35, 36, 37, 47, 78, 79, 540, 542, 546, 547]
  },
  [EBetGroupName.TRIPLE_BETS]: { tabOrder: 10, markets: [818, 819, 820] },
  [EBetGroupName.SPECIAL_BETS]: {
    tabOrder: 100,
    markets: [5, 6, 48, 49, 50, 51, 52, 55, 56, 57, 100, 122, 199, 207]
  }
};

export const TopLeagues = [
  'sr:category:4',
  'sr:category:393',
  'sr:category:392',
  'sr:category:1', // England
  'sr:category:30', // Germany
  'sr:category:32', // Spain
  'sr:category:31', // Italy
  'sr:category:7', // France
  'sr:category:46', // Turkiye
  'sr:category:35', // Netherlands
  'sr:category:44', // Portugal
  'sr:category:33', // Belgium
  'sr:category:21', // Russia
  'tp:category:1962',
  'tp:category:2185',
  'tp:category:1934',
  'tp:category:1952',
  'tp:category:1960',
  'tp:category:1954',
  'tp:category:1955',
  'tp:category:1956',
  'tp:category:1959',
  'tp:category:1919',
  'tp:category:1953',
  'tp:category:2257'
];

export const TopTournaments = [
  'sr:tournament:7' /*UEFA Champions League*/,
  'sr:tournament:679' /*UEFA Europa League*/,
  'sr:tournament:34480' /*UEFA Europa Conference League*/,
  'sr:tournament:23755' /*UEFA Nations League*/,
  'sr:tournament:465' /*UEFA SuperCup*/,
  'sr:tournament:16' /*World Cup*/,
  'sr:tournament:11' /*World Cup Qualification UEFA*/,
  'sr:tournament:1' /*EURO Cup*/,
  'sr:tournament:27' /*EURO Cup Qualification*/,
  'sr:tournament:133' /*Copa America*/,
  'sr:tournament:17' /*Premier League*/,
  'sr:tournament:19' /*England FA CUP*/,
  'sr:tournament:18' /*England Championship*/,
  'sr:tournament:18' /*England Championship Playoffs*/,
  'sr:tournament:346' /*England Community Shield*/,
  'sr:tournament:19' /*England FA Cup*/,
  'sr:tournament:23' /*Serie A*/,
  'sr:tournament:34' /*Ligue 1*/,
  'sr:tournament:339' /*France Trophee Des Champions*/,
  'sr:tournament:35' /*Bundesliga*/,
  'sr:tournament:35' /*Bundesliga, Relegation/Promotion Playoffs*/,
  'sr:tournament:217' /*Germany DFB Pokal*/,
  'sr:tournament:44' /*Germany 2. Bundesliga*/,
  'sr:tournament:44' /*Germany 2. Bundesliga Relegation/Promotion Playoffs*/,
  'sr:tournament:799' /*Germany Supercup*/,
  'sr:tournament:217' /*Germany DFB Pokal*/,
  'sr:tournament:8' /*LaLiga*/,
  'sr:tournament:329' /*La Liga Copa Del Rey*/,
  'sr:tournament:52' /*Turkey Super Lig*/,
  'sr:tournament:96' /*Turkey Cup*/,
  'sr:tournament:213' /*Spain SuperCopa*/,
  'sr:tournament:328' /*Copa Italia*/,
  'sr:tournament:341' /*Italy Supercup*/,
  'sr:tournament:238' /*Portugal Primeira Liga*/,
  'sr:tournament:238' /*Portugal Primeira Liga Relegation/Promotion*/,
  'sr:tournament:37' /*Netherlands Eredivisie*/,
  'sr:tournament:37' /*Netherlands Eredivisie Europa Playoffs*/,
  'sr:tournament:37' /*Netherlands Eredivisie Relegation/Promotion Playoffs*/,
  'sr:tournament:340' /*Johan Cruijff Schaal*/,
  'sr:tournament:132' /*NBA*/,
  'sr:tournament:878' /*International Woman Qualifying UEFA*/,
  'sr:tournament:851' /*INTERNATIONAL FRIENDLY GAMES*/,
  'sr:tournament:155' /* Argentina Superliga*/,
  'sr:tournament:325' /* Brasiliero Seri A*/
];

export const BetSlipQuerySortBy_DatePlaced_Des = 1;
export const BetSlipQuerySortBy_DatePlaced_Asc = 2;
export const BetSlipQuerySortBy_StakeAtPlaceBet_Des = 3;
export const BetSlipQuerySortBy_StakeAtPlaceBet_Asc = 4;

export const sports = {
  Football: 0,
  Basketball: 1,
  Tennis: 2,
  AmericanFootball: 3,
  Baseball: 4,
  Cricket: 5,
  Darts: 6,
  Futsal: 7,
  Handball: 8,
  IceHockey: 9,
  Rugby: 10,
  Snooker: 11,
  TableTennis: 12,
  Volleyball: 13,
  Boxing: 14,
  Other: 999
};

export const getSportName = (sportId) => Object.keys(sports).find((key) => sports[key] === sportId);

export const popularGamesId = [
  '5048',
  '5050',
  '5072',
  '5047',
  '5817',
  '304598',
  '304697',
  '5053',
  '5040'
];

export const TopSports = ['Football', 'Basketball', 'Tennis', 'IceHockey', 'Handball'];

export const OtherSports = [
  'Volleyball',
  'Futsal',
  'Rugby',
  'Snooker',
  'Baseball',
  'Boxing',
  'Darts',
  'TableTennis',
  'AmericanFootball',
  'Cricket',
  'LeagueOfLegends',
  'CounterStrike',
  'Dota2',
  'EFootball'
];

export const getBetGroups = (marketId) => {
  const betGroups = [];

  for (const key in BetGroups) {
    if (BetGroups[key].markets.includes(marketId)) {
      betGroups.push(key);
    }
  }

  if (betGroups.length) {
    return betGroups;
  }

  return null;
};

// 1 X 2
export const tripleBets = [1, 7, 8, 9, 14, 60, 61, 62, 65, 83, 84, 113, 114, 162, 235, 491];
// 1 2
export const doubleBets = [
  2, 11, 64, 86, 186, 202, 219, 251, 340, 499, 16, 66, 187, 188, 203, 223, 237, 256, 327, 493
];
// + -
export const overUnderBets = [
  18, 19, 20, 68, 69, 70, 90, 91, 92, 116, 166, 189, 225, 236, 238, 258, 310, 314, 328, 494, 605
];
