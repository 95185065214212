import * as _ from 'lodash';
import { TopTournaments } from './constants.js';

export const eventsToGroup = (events) => {
  const groups = _.groupBy(
    events.filter((e) => !!e),
    (m) => m.sport
  );
  for (const groupsKey in groups) {
    const matchesOfSport = groups[groupsKey];
    const finalArray = [];
    const leagueMatches = _.groupBy(matchesOfSport, (m) => m.leagueId);

    let topEvents = [];
    let otherEvents = [];
    for (const leagueId in leagueMatches) {
      if (_.includes(TopTournaments, leagueId)) {
        topEvents = topEvents.concat(leagueMatches[leagueId]);
      } else {
        otherEvents = otherEvents.concat(leagueMatches[leagueId]);
      }
    }

    otherEvents.forEach(e => {
      if (e.highlighted === true) {
        topEvents.push(e);
      }
    })

    otherEvents = otherEvents.filter(e => e.highlighted === false);

    const topMatchDateEvents = _.groupBy(topEvents, (m) => m.gameDate);
    const dateKeys = Object.keys(topMatchDateEvents);
    dateKeys.sort((m1, m2) => new Date(m1) - new Date(m2));
    for (let i = 0; i < dateKeys.length; i++) {
      const leagueGroups = _.groupBy(topMatchDateEvents[dateKeys[i]], (m) => m.leagueId);
      for (const leagueGroupsKey in leagueGroups) {
        const league = leagueGroups[leagueGroupsKey][0].league;
        const category = leagueGroups[leagueGroupsKey][0].category;
        finalArray.push({ category, league, matches: leagueGroups[leagueGroupsKey] });
      }
    }

    const otherMatchDateEvents = _.groupBy(otherEvents, (m) => m.gameDate);
    const otherDateKeys = Object.keys(otherMatchDateEvents);
    otherDateKeys.sort((m1, m2) => new Date(m1) - new Date(m2));
    for (let i = 0; i < otherDateKeys.length; i++) {
      const leagueGroups = _.groupBy(otherMatchDateEvents[otherDateKeys[i]], (m) => m.leagueId);
      for (const leagueGroupsKey in leagueGroups) {
        const league = leagueGroups[leagueGroupsKey][0].league;
        const category = leagueGroups[leagueGroupsKey][0].category;
        finalArray.push({ category, league, matches: leagueGroups[leagueGroupsKey] });
      }
    }

    groups[groupsKey] = finalArray;
  }
  return groups;
};

export const eventsToGroupWithoutLeague = (events) => {
  const groups = _.groupBy(
    events.filter((e) => !!e),
    (m) => m.sport
  );
  for (const groupsKey in groups) {
    const matchesOfSport = groups[groupsKey];
    const finalArray = [];
    const leagueMatches = _.groupBy(matchesOfSport, (m) => m.leagueId);

    let otherEvents = [];
    for (const leagueId in leagueMatches) {
      otherEvents = otherEvents.concat(leagueMatches[leagueId]);
    }

    const otherMatchDateEvents = _.groupBy(otherEvents, (m) => m.gameDate);
    const otherDateKeys = Object.keys(otherMatchDateEvents);
    otherDateKeys.sort((m1, m2) => new Date(m1) - new Date(m2));
    for (let i = 0; i < otherDateKeys.length; i++) {
      const leagueGroups = _.groupBy(otherMatchDateEvents[otherDateKeys[i]], (m) => m.leagueId);
      for (const leagueGroupsKey in leagueGroups) {
        const league = leagueGroups[leagueGroupsKey][0].league;
        const category = leagueGroups[leagueGroupsKey][0].category;
        finalArray.push({ category, league, matches: leagueGroups[leagueGroupsKey] });
      }
    }

    groups[groupsKey] = finalArray;
  }
  return groups;
};
